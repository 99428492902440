.formInput {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-bottom: 20px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  backdrop-filter: blur(3px);
  z-index: 5; /* Ensure it appears on top */
}

p {
  font-size: 1.5rem;
}
h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  padding: 6rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.close-modal {
  position: absolute;
  top: 10px;
  left: 9rem;
  font-size: 30px;
  color: #383838;
  cursor: pointer;
  border: none;
  background: none;
}

/* .close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: none;
} */
input {
  padding: 25px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
}
label {
  font-size: 12px;
  color: gray;
}

span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}
input:invalid[focused="true"] {
  border: 1px solid red;
}

input:invalid[focused="true"] ~ span {
  display: block;
}
