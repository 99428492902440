.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)
    ),
    url("https://images.pexels.com/photos/6985185/pexels-photo-6985185.jpeg");
  background-size: cover;
  background-position: center;
}

form {
  background-color: white;
  padding: 0px 60px;
  border-radius: 10px;
}

h1 {
  color: rgb(26, 156, 89);
  text-align: center;
}

button {
  width: 100%;
  height: 50px;
  padding: 10px;
  border: none;
  background-color: rgb(26, 156, 89);
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 30px;
}
